import axios from "axios"
import config from "../config/config"

export class HttpClient {
  client

  constructor(params) {
    this.client = axios.create({
      ...params,
      baseURL: config.API_ENDPOINT,
    })
  }

  get(url, params) {
    return this.client.get(params ? `${url}?${params}` : url)
  }

  post(url, data, progressCallback) {
    return this.client.post(url, data, progressCallback)
  }

  put(url, data) {
    return this.client.put(url, data)
  }

  delete(url, params) {
    return this.client.delete(params ? `${url}?${params}` : url)
  }

  patch(url, data) {
    return this.client.patch(url, data)
  }

  successHandler(response) {
    return response
  }

  errorHandler(error) {
    throw error
  }
}
