import React from "react"
import { MyThemeProvider } from "./src/context/ThemeContext"

export const wrapRootElement = ({ element }) => (
  <MyThemeProvider>{element}</MyThemeProvider>
)

export const onInitialClientRender = () => {
  // wait to init GTM until after React has hydrated in this lifecycle
  const dataLayer = window.dataLayer || []

  function gtag() {
    dataLayer.push(arguments)
  }
  gtag("js", new Date())

  gtag("config", "G-SP20Q0KWXE")
}
