import theme from "styled-theming"

// Brand Colors
const brandPrimaryColor = "#57ac2d"
export const brandSecondaryColor = "#152837"
const brandSecondaryDarkColor = "#11222e"
const brandSecondarySuperDarkColor = "#0d1a24"
const brandTertiaryColor = "#789BB6"
export const brandAccentColor = "#FF5900"
export const brandLightAccentColor = "#FFF7E4"
export const brandLinkColor = "#3AA6DD"
export const babyBlueColor = "#EBF3F9"

// Monochromatic Colors
export const whiteColor = "#fff"
const offWhiteColor = "#FCFCFC"
const smokeColor = "#f8f8f8"
const darkGreyColor = "#333"
const greyColor = "#555"
export const lightGreyColor = "#777"
export const darkSmokeColor = "#DDDDDD"

// Transparent
const brandPrimaryTrans15 = "rgba(87, 172, 45, 0.15)"
const brandPrimaryBrightTrans15 = "rgba(114, 245, 49, 0.15)"
const brandTertiaryTrans15 = "rgba(120, 155, 182, 0.15)"
const brandTertiaryTrans10 = "rgba(120, 155, 182, 0.10)"

// Pricing Colors

export const pricingOrangeColor = "#FFAF85"
export const pricingOrangeColorPale = "#FFF7F3"
export const pricingPurpleColor = "#A89EE5"
export const pricingPurpleColorPale = "#fafafd"
export const pricingGreenColor = "#8FC873"
export const pricingGreenColorPale = "#f8fcf8"
export const pricingFreeColor = "#bbb"

// Main Color Palette
export const brandPrimaryTheme = theme("mode", {
  light: brandPrimaryColor,
  dark: brandAccentColor,
})

export const accentTheme = theme("mode", {
  light: brandAccentColor,
  dark: brandPrimaryColor,
})

export const whiteTheme = theme("mode", {
  light: whiteColor,
  dark: brandSecondaryColor,
})

export const darkGreyTheme = theme("mode", {
  light: darkGreyColor,
  dark: whiteColor,
})

export const brandTertiaryTheme = theme("mode", {
  light: brandTertiaryColor,
  dark: brandTertiaryColor,
})

export const brandOffWhiteTheme = theme("mode", {
  light: smokeColor,
  dark: smokeColor,
})

export const brandTertiaryTrans10Theme = theme("mode", {
  light: brandTertiaryTrans10,
  dark: brandTertiaryTrans10,
})

// Typography Color Styles

export const headlineTextTheme = theme("mode", {
  light: darkGreyColor,
  dark: whiteColor,
})

export const paragraphTextTheme = theme("mode", {
  light: greyColor,
  dark: darkSmokeColor,
})

export const fadedHeadlineTextTheme = theme("mode", {
  light: lightGreyColor,
  dark: whiteColor,
})

export const fadedParagraphTextTheme = theme("mode", {
  light: lightGreyColor,
  dark: darkSmokeColor,
})

// Buttons

export const internalButtonTextTheme = theme("mode", {
  light: darkGreyColor,
  dark: darkGreyColor,
})

export const internalButtonBackgroundTheme = theme("mode", {
  light: offWhiteColor,
  dark: offWhiteColor,
})

// Borders

export const borderTheme = theme("mode", {
  light: darkSmokeColor,
  dark: brandSecondaryDarkColor,
})

// Mega Menu

export const megaMenuTheme = theme("mode", {
  light: smokeColor,
  dark: brandSecondaryColor,
})

export const darkModeToggleTheme = theme("mode", {
  light: brandTertiaryColor,
  dark: brandAccentColor,
})

// Footer Menu

export const footerMainTheme = theme("mode", {
  light: offWhiteColor,
  dark: brandSecondaryDarkColor,
})

export const footerBottomBarTheme = theme("mode", {
  light: smokeColor,
  dark: brandSecondarySuperDarkColor,
})
