import config from "../config/config"
import { planService } from "../services"
import { redirectUrl } from "../utils/common"
import { SubscriptionTerm, SystemAddOnType } from "../utils/enumUtil"

export function saveTrialPlanDetails(planName, themeState, setThemeState) {
    const pricingPlanResponse = themeState.pricingPlanResponse || null
    const plans = pricingPlanResponse ? pricingPlanResponse.plans : [];
    const planDetails = plans.find(x => x.name.toLowerCase() === planName.toLowerCase())
    if (!planDetails) {
        return
    }
    const affiliateId = themeState.affiliateId
    const promoId = pricingPlanResponse && pricingPlanResponse.promotionDetails
        ? pricingPlanResponse.promotionDetails.promoId
        : null
    const {
        monthlyPrice,
        annualyPrice,
        minimumContactLimit,
        minimumCreditLimit,
        minimumSubAccountsLimit,
        minimumUsersAccountLimit,
        maximumSubAccountsLimit,
        maximumUsersAccountLimit,
    } = planDetails

    const addOnsAllowedInPlan = planDetails.addOnsAllowedInPlan || null
    const userAddOnInfo = addOnsAllowedInPlan
        ? addOnsAllowedInPlan.find(a => a.type === SystemAddOnType.Users)
        : null

    const subAccountAddOnInfo = addOnsAllowedInPlan
        ? addOnsAllowedInPlan.find(a => a.type === SystemAddOnType.SubAccounts)
        : null

    const pricePerUser = userAddOnInfo == null ? 0 : userAddOnInfo.price
    const pricePerSubAccount = subAccountAddOnInfo ? subAccountAddOnInfo.price : 0
    const pricePerCredits = (planDetails && planDetails.creditPriceAddedToPlan) || 0
    const requestVM = {
        PlanId: planDetails.id,
        PromoCodeId: promoId,
        GrossMonthlyTotal: monthlyPrice,
        GrossContractTotal: monthlyPrice,
        PlanMonthlyPrice: monthlyPrice,
        PlanContractPrice: annualyPrice,
        PlanContacts: minimumContactLimit,
        PlanCredits: minimumCreditLimit,
        PlanSubAccounts: minimumSubAccountsLimit,
        PlanUsers: minimumUsersAccountLimit,
        MaxSubAccounts: maximumSubAccountsLimit,
        MaxUsers: maximumUsersAccountLimit,
        PricePerAdditionalCredit: pricePerCredits,
        PricePerAdditionalSubAccount: pricePerSubAccount,
        PricePerAdditionalUser: pricePerUser,
        SelectedCreditCount: minimumCreditLimit,
        SelectedContactCount: minimumContactLimit,
        SelectedSubAccountsCount: minimumSubAccountsLimit,
        SelectedUsersCount: minimumUsersAccountLimit,
        PaymentTerm: SubscriptionTerm.Monthly,
        IsSignUpForTrial: true,
        GrossTotal: monthlyPrice,
        AffiliateSourceId: affiliateId,
        PlanPriceOnPromoExpire: monthlyPrice
    }
    planService.saveAddOnitemsDetailForSignUpFromPricingPlan(requestVM)
        .then(response => {
            if (response) {
                const url = `${config.WEB_ENDPOINT}signup/${response.responseId}`
                redirectUrl(url)
            }
            setThemeState({
                ...themeState,
                savePlanAPIInprogress: false
            })
        })
}