import {
  isUndefinedNullOrEmpty,
  numberWithCommas,
} from "../../utils/common"
import {
  IncentiveType,
  AdditionalUserIncentives,
  IncentivesExpirationAfter,
  AdditionalCreditIncentiveType,
  DiscountPriceBasedOn,
  DiscountPriceApplicableOn,
  DiscountPriceExpirationAfter,
  AdditionalSubAccountIncentives,
  AddOnFeatureType,
  AdditionalContactIncentives,
} from "../../utils/enumUtil"

export class PromoApplySettings {
  promoCode;
  promoId;
  promoName;
  incentives;
  isShowPromoBox;
  isPromoApplied;
  incentiveDescriptions;
  errorMessage;
  constructor(data) {
    this.promoCode = (data && data.promoCode) || ""
    this.promoId = (data && data.promoId) || ""
    this.promoName = (data && data.promoName) || ""
    this.incentives = (data && data.incentives) || []
    this.isShowPromoBox = (data && data.isShowPromoBox) || false
    this.isPromoApplied = (data && data.isPromoApplied) || false
    this.incentiveDescriptions = (data && data.incentiveDescriptions) || []
    this.errorMessage = (data && data.errorMessage) || ""
  }
}

export class VerifyPromoResponse {
  isValidPromo
  invalidPromoReason
  promoId
  promoCodeName
  isPurchaseAmountSet
  purchaseAmount
  promoIncentives
  errorMessage
  constructor(data) {
    this.isValidPromo = (data && data.isValidPromoCode) || false
    this.invalidPromoReason = (data && data.invalidPromoCodeReason) || ""
    this.promoId = (data && data.promoCodeId) || ""
    this.promoCodeName = (data && data.promoCodeName) || ""
    this.purchaseAmount = (data && data.purchaseAmount) || 0
    this.isPurchaseAmountSet = (data && data.isPurchaseAmountSet) || false
    this.promoIncentives = (data && data.promoIncentiveList) || []
    this.errorMessage = VerifyPromoResponse.deserializedErrorMessage(this.invalidPromoReason)
  }

  static deserializedErrorMessage(failureReason) {
    switch (failureReason) {
      case InvalidPromoCodeReason.PromotionInvalidWithBeginingDate:
      case InvalidPromoCodeReason.PromotionInvalidWithEndDate:
      case InvalidPromoCodeReason.PromotionInvalidWithUserLimit:
      case InvalidPromoCodeReason.PromotionNotFound:
      case InvalidPromoCodeReason.PlanNotFound:
        return "The promotion is invalid. No discount or enhancement will be applied."
      default:
        return "";
    }
  }
}

export class IncentiveSummaryForPromoApplicable {
  type
  description
  expirationDescription
  expirationMonths
  savingLabel
  savingAmount

  constructor(data) {
    this.type = (data && data.type) || ""
    this.description = (data && data.description) || ""
    this.expirationDescription = (data && data.expirationDescription) || ""
    this.expirationMonths = (data && data.expirationMonths) || 0
    this.savingLabel = (data && data.savingLabel) || ""
    this.savingAmount = (data && data.savingAmount) || 0
  }
}

//INVALIDA PROMO CODE REASON
const InvalidPromoCodeReason = {
  PromotionNotFound: "PromotionNotFound",
  PlanNotFound: "PlanNotFound",
  PromotionInvalidWithBeginingDate: "PromotionInvalidWithBeginingDate",
  PromotionInvalidWithEndDate: "PromotionInvalidWithEndDate",
  PromotionInvalidWithUserLimit: "PromotionInvalidWithUserLimit",
  PromotionInvalidWithSubscriptionAmount:
    "PromotionInvalidWithSubscriptionAmount",
  PromotionInvalidWithPurchaseAmount: "PromotionInvalidWithPurchaseAmount",
  PromotionInvalidWithBillingFrequency: "PromotionInvalidWithBillingFrequency",
  PromotionInvalidWithSelectedPlan: "PromotionInvalidWithSelectedPlan",
}