import { VerifyPromoResponse } from "./"
import {
  pricingGreenColor,
  pricingOrangeColor,
  pricingPurpleColor,
} from "../../theme"
import {
  AddOnFeatureType,
  AdditionalContactIncentives,
  AdditionalCreditIncentiveType,
  AdditionalSubAccountIncentives,
  AdditionalUserIncentives,
  DiscountPriceApplicableOn,
  DiscountPriceBasedOn,
  DiscountPriceExpirationAfter,
  IncentiveType,
  IncentivesExpirationAfter,
  StandardPlanName,
  SubscriptionTerm,
  SystemAddOnType,
} from "../../utils/enumUtil"
import {
  getFormattedAmount,
  getKeyFromObjectValue,
  isUndefinedNullOrEmpty,
  useNumberWithCommas,
} from "../../utils/common"

export class PricingPlanResponseModel {
  plans
  promotionDetails
  constructor(data) {
    this.plans =
      data?.signUpPlans?.map((plan) => {
        return new PlanModel(plan)
      }) || []
    this.promotionDetails = new VerifyPromoResponse(
      data && data.validatePromotionDetails
    )
  }
}

export class LinkedAddOns {
  type
  price
  count
  pricePerAddOn
  availableInPlan
  freeAddOnCount
  constructor(data) {
    this.type = data?.type || ""
    this.price = data?.price || 0
    this.count = data?.count || 0
    this.pricePerAddOn = data?.pricePerAddOn || 0
    this.availableInPlan = data?.availableInPlan || false
    this.freeAddOnCount = data?.freeAddOnCount || 0
  }
}

export class IncentiveSummary {
  type
  description
  expirationDescription
  expirationMonths
  savingLabel
  savingAmount
  showInSavings

  constructor(data) {
    this.type = data?.type || ""
    this.description = data?.description || ""
    this.expirationDescription = data?.expirationDescription || ""
    this.expirationMonths = data?.expirationMonths || 0
    this.savingLabel = data?.savingLabel || ""
    this.savingAmount = data?.savingAmount || 0
    this.showInSavings = data?.showInSavings === true
  }
}

export class PlanModel {
  id
  name
  color
  backgroundColor
  monthlyPrice
  annualyPrice
  isTrial
  addOnsAllowedInPlan
  featureAllowedInPlan
  creditPriceAddedToPlan
  minimumContactLimit
  maximumContactLimit
  minimumCreditLimit
  maximumCreditLimit
  maximumUsersAccountLimit
  minimumUsersAccountLimit
  minimumSubAccountsLimit
  maximumSubAccountsLimit
  creditPerEmail
  creditPerSMSRoute
  creditPerMMSRoute
  creditRollOverFrequencyLength
  creditRollOverFrequency
  isCurrentPlan
  amount
  subscriptionTerm
  isQuickStartAvailable
  isKnowledgeBaseAvailable
  isTrainingVideosAvailable
  isTrainingWebinarsAvailable
  isEmailSupportAvailable
  isChatSupportAvailable
  isUSPhoneSupportAvailable

  planPrice
  selectedContacts
  selectedCredits
  selectedSubAccounts
  selectedUsers
  purchaseAmount
  purchaseAmountWithPromotion
  addOns
  incentives

  constructor(data) {
    this.id = data?.id || ""
    this.name = data?.name || ""
    this.color =
      data && data.color && data.color.hexValue
        ? data.color.hexValue.trim().toUpperCase()
        : this.name.toLowerCase() === StandardPlanName.Essentials.toLowerCase()
          ? pricingOrangeColor
          : this.name.toLowerCase() === StandardPlanName.Premium.toLowerCase()
            ? pricingPurpleColor
            : pricingGreenColor
    this.backgroundColor = PlanModel.deserializeRGBABackgroundColor(data?.color)
    this.monthlyPrice = data?.monthlyPrice || 0
    this.annualyPrice = data?.annualyPrice || 0
    this.isTrial = data?.isTrial || false
    this.addOnsAllowedInPlan = data?.addOnsAllowedInPlan || []
    this.featureAllowedInPlan = data?.featureAllowedInPlan || []
    this.creditPriceAddedToPlan = data?.creditPriceAddedToPlan || 0
    this.minimumContactLimit = data?.minimumContactLimit || 0
    this.maximumContactLimit = data?.maximumContactLimit || 0
    this.minimumCreditLimit = data?.minimumCreditLimit || 0
    this.maximumCreditLimit = data?.maximumCreditLimit || 0
    this.maximumUsersAccountLimit = data?.maximumUsersAccountLimit || 0
    this.minimumUsersAccountLimit = data?.minimumUsersAccountLimit || 0
    this.minimumSubAccountsLimit = data?.minimumSubAccountsLimit || 0
    this.maximumSubAccountsLimit = data?.maximumSubAccountsLimit || 0
    this.creditPerEmail = data?.creditPerEmail || 0
    this.creditPerSMSRoute = data?.creditPerSMSRoute || 0
    this.creditPerMMSRoute = data?.creditPerMMSRoute || 0
    this.creditRollOverFrequencyLength =
      data?.creditRollOverFrequencyLength || 0
    this.creditRollOverFrequency = data?.creditRollOverFrequency || ""
    this.isCurrentPlan = data?.isCurrentPlan || false
    this.amount = data?.amount || 0
    this.isQuickStartAvailable = data?.isQuickStartAvailable || false
    this.isKnowledgeBaseAvailable = data?.isKnowledgeBaseAvailable || false
    this.isTrainingVideosAvailable = data?.isTrainingVideosAvailable || false
    this.isTrainingWebinarsAvailable =
      data?.isTrainingWebinarsAvailable || false
    this.isEmailSupportAvailable = data?.isEmailSupportAvailable || false
    this.isChatSupportAvailable = data?.isChatSupportAvailable || false
    this.isUSPhoneSupportAvailable = data?.isUSPhoneSupportAvailable || false

    this.planPrice = data?.planPrice || 0
    this.selectedContacts = data?.selectedContacts || this.minimumContactLimit
    this.selectedCredits = data?.selectedCredits || this.minimumCreditLimit
    this.selectedSubAccounts =
      data?.selectedSubAccounts || this.minimumSubAccountsLimit
    this.selectedUsers = data?.selectedUsers || this.minimumUsersAccountLimit
    this.purchaseAmount = data?.purchaseAmount || this.amount
    this.purchaseAmountWithPromotion =
      data?.purchaseAmountWithPromotion || this.amount
    this.addOns = data?.addOns || []
    this.incentives = data?.incentives || []
  }

  static deserializeRGBABackgroundColor(colorData) {
    return colorData?.rgbValue
      ? `rgba(${colorData.rgbValue.r},${colorData.rgbValue.g},${colorData.rgbValue.b},${
          (colorData.rgbValue.a * 5) / 100
        })`
      : ""
  }

  static deserializePlan(
    plan,
    isAnnualSubscription,
    currentPlanAddOns,
    incentives
  ) {
    const contactLimits = this.getContactMinMaxLimitWithIncentives(
      incentives,
      plan
    )
    const creditLimits = this.getCreditMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const subAccountLimits = this.getSubMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const userLimits = this.getUserMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    plan.subscriptionTerm = isAnnualSubscription
      ? SubscriptionTerm.Annually
      : SubscriptionTerm.Monthly
    plan.planPrice = isAnnualSubscription
      ? plan.annualyPrice
      : plan.monthlyPrice
    plan.planCredits = isAnnualSubscription
      ? plan.minimumCreditLimit * 12
      : plan.minimumCreditLimit
    plan.addOns = []
    PlanModel.updateSelectedAddOns(plan, incentives, isAnnualSubscription)
    Object.keys(AddOnFeatureType).map((key) => {
      const addOn = currentPlanAddOns.find((x) => x.featureType === key)
      if (!addOn) return
      var months = isAnnualSubscription ? 12 : 1
      if (addOn.featureCount > 0) {
        addOn.featureCount = addOn.featureCount || 0
        let addOnCount = addOn.featureCount
        switch (addOn.featureType) {
          case AddOnFeatureType.Contacts:
            const currentPlanSelectedContacts =
              contactLimits.minValue + addOnCount
            //const selectedContacts = contactLimits.minValue + addOnCount;
            if (
              contactLimits.minValue === -1 ||
              currentPlanSelectedContacts <= contactLimits.minValue
            ) {
              addOnCount = 0
            }
            // else if (selectedContacts > contactLimits.minValue) {
            //   addOnCount = contactLimits.maxValue - contactLimits.minValue;
            // } else {
            //   addOnCount = currentPlanSelectedContacts - contactLimits.minValue;
            // }
            plan.selectedContacts = contactLimits.minValue + addOnCount
            break
          case AddOnFeatureType.Credits:
            const currentPlanSelectedCredits =
              creditLimits.minValue + addOnCount
            const selectedCredits = creditLimits.minValue + addOnCount
            if (
              creditLimits.minValue === -1 ||
              currentPlanSelectedCredits <= creditLimits.minValue
            ) {
              addOnCount = 0
            }
            // else if (selectedCredits > plan.maximumCreditLimit) {
            //   addOnCount = plan.maximumCreditLimit - plan.minimumCreditLimit;
            // } else {
            //   addOnCount = currentPlanSelectedCredits - plan.minimumCreditLimit;
            // }
            plan.selectedCredits = creditLimits.minValue + addOnCount
            months = 1
            break
          case AddOnFeatureType.SubAccount:
            const currentPlanSelectedSubAccounts =
              subAccountLimits.minValue + addOnCount
            //const selectedSubAccounts = plan.minimumSubAccountsLimit + addOnCount;
            if (
              plan.minimumSubAccountsLimit === -1 ||
              currentPlanSelectedSubAccounts <= subAccountLimits.minValue
            ) {
              addOnCount = 0
            }
            // else if (selectedSubAccounts > plan.maximumSubAccountsLimit) {
            //   addOnCount = plan.maximumSubAccountsLimit - plan.minimumSubAccountsLimit;
            // } else {
            //   addOnCount = selectedSubAccounts - plan.minimumSubAccountsLimit;
            // }
            plan.selectedSubAccounts = subAccountLimits.minValue + addOnCount
            if (plan.selectedSubAccounts > subAccountLimits.maxValue) {
              plan.selectedSubAccounts = subAccountLimits.maxValue
            }
            break
          case AddOnFeatureType.User:
            const currentPlanSelectedUsers =
              userLimits.minValue + addOn.featureCount
            const selectedUsers =
              plan.minimumUsersAccountLimit + addOn.featureCount
            if (
              plan.minimumUsersAccountLimit === -1 ||
              currentPlanSelectedUsers <= userLimits.minValue
            ) {
              addOnCount = 0
            }
            // else if (selectedUsers > plan.maximumUsersAccountLimit) {
            //   addOnCount = plan.maximumUsersAccountLimit - plan.minimumUsersAccountLimit;
            // } else {
            //   addOnCount = selectedUsers - plan.minimumUsersAccountLimit;
            // }
            plan.selectedUsers = userLimits.minValue + addOnCount
            break
        }
        plan.addOns.push(
          new LinkedAddOns({
            type: addOn.featureType,
            count: addOnCount,
            pricePerAddOn: addOn.perUnitPrice,
            price: addOn.perUnitPrice * addOn.featureCount * months,
          })
        )
      }
    })
    plan = PlanModel.updatePlanSummaryWithIncentives(
      plan,
      incentives,
      isAnnualSubscription
    )
    return plan
  }

  static updatePlanSummaryWithIncentives(
    plan,
    incentives,
    isAnnualSubscription
  ) {
    plan.planPrice = isAnnualSubscription
      ? plan.annualyPrice
      : plan.monthlyPrice
    plan.addOns = PlanModel.calculateAddOns(
      plan,
      incentives,
      isAnnualSubscription
    )
    plan.incentives = PlanModel.deserializeIncentives(
      plan,
      incentives,
      isAnnualSubscription
    )
    const totalSavings =
      plan.incentives?.reduce(
        (a, b) =>
          b.type === IncentiveType.Credits ||
          b.type === IncentiveType.SubAccounts ||
          b.type === IncentiveType.Users ||
          b.type === IncentiveType.DiscountPrice
            ? a + b.savingAmount
            : a,
        0
      ) || 0
    const totalSavingsWithoutDiscount =
      plan.incentives?.reduce(
        (a, b) =>
          b.type === IncentiveType.Credits ||
          b.type === IncentiveType.SubAccounts ||
          b.type === IncentiveType.Users
            ? a + b.savingAmount
            : a,
        0
      ) || 0
    const totalAddOnPrice = PlanModel.totalAddOnPrice(plan.addOns)
    plan.purchaseAmount =
      plan.planPrice + totalAddOnPrice - totalSavingsWithoutDiscount
    plan.purchaseAmountWithPromotion =
      plan.planPrice + totalAddOnPrice - totalSavings
    const introductoryIncentive = incentives.find(
      (i) => IncentiveType[i.type] === IncentiveType.IntroductoryPrice
    )
    if (introductoryIncentive?.introductoryPrice) {
      let introPrice = introductoryIncentive.introductoryPrice.amount
      if (isAnnualSubscription) {
        const discountPriceWithIntro =
          plan.incentives?.reduce(
            (a, b) =>
              b.type === IncentiveType.IntroductoryPrice
                ? a + b.savingAmount
                : a,
            0
          ) || 0
        introPrice = plan.planPrice - discountPriceWithIntro
      }
      plan.purchaseAmountWithPromotion =
        introPrice + totalAddOnPrice - totalSavingsWithoutDiscount
    }
    PlanModel.updateSelectedAddOns(plan, incentives, isAnnualSubscription)
    return plan
  }

  static updateSelectedAddOns(plan, incentives, isAnnualSubscription) {
    const creditLimits = PlanModel.getCreditMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const userLimits = PlanModel.getUserMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const subAccountLimits = PlanModel.getSubMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )

    const creditAddOns = plan.addOns?.find(
      (f) => f.type === AddOnFeatureType.Credits
    )
    const subAccountAddOn = plan.addOns?.find(
      (f) => f.type === AddOnFeatureType.SubAccount
    )
    const userAddOns = plan.addOns?.find(
      (f) => f.type === AddOnFeatureType.User
    )

    if (creditAddOns) {
      plan.selectedCredits = creditLimits.minValue + creditAddOns.count
    } else if (creditLimits.maxValue < plan.selectedCredits) {
      plan.selectedCredits = creditLimits.minValue
    } else if (creditLimits.minValue > plan.selectedCredits) {
      plan.selectedCredits = creditLimits.minValue
    }

    if (subAccountAddOn) {
      plan.selectedSubAccounts =
        subAccountLimits.minValue + subAccountAddOn.count
    } else if (
      subAccountLimits.maxValue !== -1 &&
      subAccountLimits.maxValue < plan.selectedSubAccounts
    ) {
      plan.selectedSubAccounts = subAccountLimits.minValue
    } else if (subAccountLimits.minValue > plan.selectedSubAccounts) {
      plan.selectedSubAccounts = subAccountLimits.minValue
    }

    if (userAddOns) {
      plan.selectedUsers = userLimits.minValue + userAddOns.count
    } else if (
      userLimits.maxValue !== -1 &&
      userLimits.maxValue < plan.selectedUsers
    ) {
      plan.selectedUsers = userLimits.minValue
    } else if (userLimits.minValue > plan.selectedUsers) {
      plan.selectedUsers = userLimits.minValue
    }
  }

  static calculateAddOns(plan, incentives, isAnnualSubscription) {
    if (!plan) return []
    const userAddOnInfo =
      plan.addOnsAllowedInPlan?.find((a) => a.type === SystemAddOnType.Users) ||
      null
    const subAccountAddOnInfo =
      plan.addOnsAllowedInPlan?.find(
        (a) => a.type === SystemAddOnType.SubAccounts
      ) || null

    let pricePerUser = userAddOnInfo?.price || 0
    let pricePerSubAccount = subAccountAddOnInfo?.price || 0
    let pricePerCredits = plan.creditPriceAddedToPlan || 0
    var months = isAnnualSubscription ? 12 : 1
    var expirationMonths = isAnnualSubscription ? 12 : 1
    const userIncentive = incentives.find(
      (i) => IncentiveType[i.type] === IncentiveType.Users
    )
    const subAccountIncentive = incentives.find(
      (i) => IncentiveType[i.type] === IncentiveType.SubAccounts
    )
    const creditIncentive = incentives.find(
      (i) => IncentiveType[i.type] === IncentiveType.Credits
    )
    const isSubAccountAddOnAvaialbleInPlan =
      subAccountAddOnInfo?.isAvailableInPlan === true
    const isUserAddOnAvaialbleInPlan = userAddOnInfo?.isAvailableInPlan === true

    if (
      userIncentive?.users?.isSetUserPrice &&
      userAddOnInfo?.price > userIncentive.users.pricePerUser
    ) {
      pricePerUser = userIncentive.users.pricePerUser
    }

    if (
      subAccountIncentive?.subAccounts?.isSetSubAccountPrice &&
      subAccountAddOnInfo?.price >
        subAccountIncentive.subAccounts.pricePerSubAccount
    ) {
      pricePerSubAccount = subAccountIncentive.subAccounts.pricePerSubAccount
    }
    if (
      creditIncentive?.credits?.isSetCreditPrice &&
      pricePerCredits > creditIncentive.credits.pricePerCredit
    ) {
      pricePerCredits = creditIncentive.credits.pricePerCredit
    }

    let newAddOns = []
    const creditLimits = this.getCreditMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const userLimits = this.getUserMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )
    const subAccountLimit = this.getSubMinMaxLimitWithIncentives(
      incentives,
      plan,
      isAnnualSubscription
    )

    if (
      plan.selectedCredits !== -1 &&
      plan.selectedCredits > creditLimits.minValue
    ) {
      if (
        creditIncentive?.credits?.isExpirationSet &&
        creditIncentive?.credits?.expireAfterValue > 0 &&
        isAnnualSubscription
      ) {
        expirationMonths = creditIncentive.credits.expireAfterValue
      }
      expirationMonths =
        expirationMonths == months
          ? 0
          : expirationMonths > 12
            ? 12
            : expirationMonths
      var price =
        pricePerCredits *
        (plan.selectedCredits - creditLimits.minValue) *
        months
      if (isAnnualSubscription && expirationMonths > 0) {
        months = expirationMonths < months ? months - expirationMonths : months
        const addOnCredits = plan.selectedCredits - creditLimits.minValue
        const priceWithoutExpirationMonths =
          (plan.creditPriceAddedToPlan || 0) * addOnCredits * months
        const priceWithExpirationMonths =
          pricePerCredits * addOnCredits * expirationMonths
        price = priceWithExpirationMonths + priceWithoutExpirationMonths
      }
      newAddOns.push(
        new LinkedAddOns({
          type: AddOnFeatureType.Credits,
          count: plan.selectedCredits - creditLimits.minValue,
          pricePerAddOn: pricePerCredits,
          price: price,
          availableInPlan: true,
          freeAddOnCount: creditLimits.freeAddOns,
        })
      )
    }
    if (
      plan.selectedSubAccounts !== -1 &&
      plan.selectedSubAccounts > subAccountLimit.minValue &&
      subAccountLimit.minValue !== -1
    ) {
      months = isAnnualSubscription ? 12 : 1
      expirationMonths = 0
      if (
        subAccountIncentive?.subAccounts?.isExpirationSet &&
        subAccountIncentive?.subAccounts?.expireAfterValue > 0 &&
        isAnnualSubscription
      ) {
        expirationMonths = subAccountIncentive?.subAccounts.expireAfterValue
      }
      expirationMonths =
        expirationMonths == months
          ? 0
          : expirationMonths > 12
            ? 12
            : expirationMonths
      var price =
        pricePerSubAccount *
        (plan.selectedSubAccounts - subAccountLimit.minValue) *
        months
      if (isAnnualSubscription && expirationMonths > 0) {
        months = expirationMonths < months ? months - expirationMonths : months
        const addOnSubAccounts =
          plan.selectedSubAccounts - subAccountLimit.minValue
        const priceWithoutExpirationMonths =
          (subAccountAddOnInfo?.price || 0) * addOnSubAccounts * months
        const priceWithExpirationMonths =
          pricePerSubAccount * addOnSubAccounts * expirationMonths
        price = priceWithExpirationMonths + priceWithoutExpirationMonths
      }
      newAddOns.push(
        new LinkedAddOns({
          type: AddOnFeatureType.SubAccount,
          count: plan.selectedSubAccounts - subAccountLimit.minValue,
          pricePerAddOn: pricePerSubAccount,
          price: price,
          availableInPlan: isSubAccountAddOnAvaialbleInPlan,
          freeAddOnCount: subAccountLimit.freeAddOns,
        })
      )
    }
    if (
      plan.selectedUsers !== -1 &&
      plan.selectedUsers > userLimits.minValue &&
      userLimits.minValue !== -1
    ) {
      months = isAnnualSubscription ? 12 : 1
      expirationMonths = 0
      if (
        userIncentive?.users?.isExpirationSet &&
        userIncentive?.users?.expireAfterValue > 0 &&
        isAnnualSubscription
      ) {
        expirationMonths = userIncentive?.users.expireAfterValue
      }
      expirationMonths =
        expirationMonths == months
          ? 0
          : expirationMonths > 12
            ? 12
            : expirationMonths
      var price =
        pricePerUser * (plan.selectedUsers - userLimits.minValue) * months
      if (isAnnualSubscription && expirationMonths > 0) {
        months = expirationMonths < months ? months - expirationMonths : months
        const addOnUsers = plan.selectedUsers - userLimits.minValue
        const priceWithoutExpirationMonths =
          (userAddOnInfo?.price || 0) * addOnUsers * months
        const priceWithExpirationMonths =
          pricePerUser * addOnUsers * expirationMonths
        price = priceWithExpirationMonths + priceWithoutExpirationMonths
      }
      newAddOns.push(
        new LinkedAddOns({
          type: AddOnFeatureType.User,
          count: plan.selectedUsers - userLimits.minValue,
          pricePerAddOn: pricePerUser,
          price: price,
          availableInPlan: isUserAddOnAvaialbleInPlan,
          freeAddOnCount: userLimits.freeAddOns,
        })
      )
    }
    return newAddOns
  }

  static totalAddOnPrice(addOns) {
    return (
      addOns?.reduce((a, b) => a + (b.availableInPlan ? b.price : 0), 0) || 0
    )
  }

  static deserializeIncentives(plan, incentiveList, isAnnualSubscription) {
    if (!plan || !incentiveList) return []
    let incentives = []
    Object.keys(IncentiveType).map((key) => {
      const incentive = incentiveList.find((x) => x.type === key)
      if (!incentive) return

      const totalSavingsAmount = incentives.reduce(
        (a, b) =>
          b.type === IncentiveType.Credits ||
          b.type === IncentiveType.SubAccounts ||
          b.type === IncentiveType.Users
            ? a + b.savingAmount
            : a,
        0
      )

      let description = ""
      let expirationDescription = ""
      let savingLabel = ""
      let savingAmount = 0
      let showInSavings = true
      let incentiveTypeDetail = null
      let nowDate = new Date()
      let expirationDateTime = new Date()
      let expirationMonths = 0
      let pricePerAddOn = 0
      let additionalAddOnCount = 0
      const newAddOns = plan.addOns
      const creditAddOn = newAddOns.find(
        (x) => x.type === AddOnFeatureType.Credits
      )
      const subAccountAddOn = newAddOns.find(
        (x) => x.type === AddOnFeatureType.SubAccount
      )
      const userAddOn = newAddOns.find((x) => x.type === AddOnFeatureType.User)
      const contactAddOn = newAddOns.find(
        (x) => x.type === AddOnFeatureType.Contacts
      )
      let totalAddOnPrice = PlanModel.totalAddOnPrice(newAddOns)
      let isMultipleItemsInIncentive = false
      let planPrice = plan.planPrice
      const planColor = plan.color
      switch (IncentiveType[incentive.type]) {
        case IncentiveType.DiscountPrice:
          {
            let amountForApplicableDiscount = 0
            incentiveTypeDetail = incentive ? incentive.discountPrice : null
            //FIND DISCOUNT AMOUNT
            if (incentiveTypeDetail) {
              totalAddOnPrice = totalAddOnPrice - totalSavingsAmount
              //  For Eg. discount = 50% on entire purchase
              //  planPrice = $20 monthly;
              //  totalAddOnPrice = $0.6
              //IF ANNUAL SUBSCRIPTION THEN GET 1 MOTNH PLAN PRICE
              if (isAnnualSubscription) {
                planPrice = planPrice / 12
                totalAddOnPrice = totalAddOnPrice / 12
                expirationMonths = 12
              }

              savingLabel = "Subscription Price"
              expirationDescription = "No Expiration"

              let discountPrice = 0
              const totalPrice = planPrice + totalAddOnPrice
              switch (
                DiscountPriceApplicableOn[
                  incentiveTypeDetail.discountPriceApplicableOn
                ]
              ) {
                case DiscountPriceApplicableOn.Plan:
                  amountForApplicableDiscount = planPrice
                  break
                case DiscountPriceApplicableOn.AddOns:
                  amountForApplicableDiscount = totalAddOnPrice
                  break
                case DiscountPriceApplicableOn.EntirePurchase:
                  amountForApplicableDiscount = totalPrice
                  break
              }

              if (incentiveTypeDetail.isExpirationSet) {
                switch (incentiveTypeDetail.expireAfter) {
                  case DiscountPriceExpirationAfter.Months:
                    expirationMonths = incentiveTypeDetail.expireAfterValue
                    break
                  case DiscountPriceExpirationAfter.Payments:
                    {
                      const paymentsCount = incentiveTypeDetail.expireAfterValue
                      expirationMonths = isAnnualSubscription
                        ? paymentsCount * 12 //12 MEANS = 12 MOTNHS IN YEAR
                        : paymentsCount // FOR MONTHLY MONTHS = PAYMENT'S COUNT
                    }
                    break
                }

                expirationDateTime = new Date(
                  expirationDateTime.setMonth(
                    nowDate.getMonth() + expirationMonths
                  )
                )
                expirationDescription = `${
                  expirationDateTime.getMonth() + 1
                }/${expirationDateTime.getDate()}/${expirationDateTime.getFullYear()}`
              }

              const discountPriceBasedOn =
                DiscountPriceBasedOn[incentiveTypeDetail.discountPriceBasedOn]
              var discountValue = isAnnualSubscription
                ? incentiveTypeDetail.discountValue / 12
                : incentiveTypeDetail.discountValue
              if (isAnnualSubscription && expirationMonths > 0) {
                discountValue = discountValue * expirationMonths
              }
              switch (discountPriceBasedOn) {
                case DiscountPriceBasedOn.Amount:
                  description = `$${incentiveTypeDetail.discountValue}`
                  //  discountPrice = 50
                  discountPrice = discountValue
                  break
                case DiscountPriceBasedOn.Percentage:
                  description = `${incentiveTypeDetail.discountValue}%`
                  //  discountPrice = (20 * 50) /100 = $10.3
                  discountPrice =
                    (amountForApplicableDiscount *
                      incentiveTypeDetail.discountValue) /
                    100
                  break
                case DiscountPriceBasedOn.SetAmount:
                  // if (plan.isCurrentPlan) {
                  description = `$${incentiveTypeDetail.discountValue}`
                  //discountPrice = amountForApplicableDiscount - incentiveTypeDetail.discountValue;
                  //}
                  discountPrice =
                    amountForApplicableDiscount > 0
                      ? amountForApplicableDiscount - discountValue
                      : 0
                  showInSavings = false
                  break
              }

              description = `${
                discountPriceBasedOn === DiscountPriceBasedOn.SetAmount
                  ? "Set "
                  : "Decrease By "
              }<span style="color:${planColor}; font-weight: 600">
                         ${description} </span>`
              switch (
                DiscountPriceApplicableOn[
                  incentiveTypeDetail.discountPriceApplicableOn
                ]
              ) {
                case DiscountPriceApplicableOn.Plan:
                  description = `${description} Subscription Price | Plan`
                  savingAmount = discountPrice
                  break
                case DiscountPriceApplicableOn.AddOns:
                  description = `${description} Subscription Price | Add-On`
                  savingAmount = discountPrice
                  break
                case DiscountPriceApplicableOn.EntirePurchase:
                  description = `${description} Subscription Price | Entire Purchase`
                  savingAmount = discountPrice
                  break
              }
            }

            // if (isAnnualSubscription && savingAmount > 0) {
            //   amountForApplicableDiscount = amountForApplicableDiscount * 12;
            //   if (expirationMonths > 0 && expirationMonths <= 12) {
            //     savingAmount = savingAmount * expirationMonths;
            //   } else {
            //     savingAmount = savingAmount * 12;
            //   }
            // }

            //EG. $100 OFF FOR 8 MONTHS AND ANNUAL PRICE IS $275 SO
            //HERE FOR 8 MONTHS WE GOT $800  SO WE DO NOT HAVE TO PAY FOR THE $275
            if (savingAmount > amountForApplicableDiscount) {
              savingAmount = amountForApplicableDiscount
            }
          }
          break

        case IncentiveType.Credits:
          {
            incentiveTypeDetail = incentive ? incentive.credits : null
            if (incentiveTypeDetail) {
              expirationDescription = "No Expiration"
              let additionalCredits = 0
              let isSetCredits = false
              expirationMonths = isAnnualSubscription ? 12 : 1
              if (incentiveTypeDetail.isExpirationSet) {
                switch (incentiveTypeDetail.expireAfter) {
                  case IncentivesExpirationAfter.Months:
                    {
                      expirationMonths = incentiveTypeDetail.expireAfterValue
                      expirationDateTime = new Date(
                        expirationDateTime.setMonth(
                          nowDate.getMonth() +
                            incentiveTypeDetail.expireAfterValue
                        )
                      )
                    }
                    break
                  default:
                    expirationDateTime = null
                    break
                }

                if (expirationDateTime) {
                  expirationDescription = `${
                    expirationDateTime.getMonth() + 1
                  }/${expirationDateTime.getDate()}/${expirationDateTime.getFullYear()}`
                }
              }

              if (incentiveTypeDetail.isSetAdditionalCredits) {
                switch (
                  AdditionalCreditIncentiveType[
                    incentiveTypeDetail.additionalCreditType
                  ]
                ) {
                  case AdditionalCreditIncentiveType.IncreaseNumberOfCredits:
                    additionalCredits = incentiveTypeDetail.additionalCredits
                    isMultipleItemsInIncentive = true
                    description = `<span style="color:${planColor}; font-weight: 600">
                      ${useNumberWithCommas(additionalCredits, false)}</span> Extra Credit(s)`
                    break
                  case AdditionalCreditIncentiveType.IncreaseCreditsByPercentage:
                    //GET CREDIT'S PERCENTAGE COUNT
                    additionalCredits =
                      (plan.minimumCreditLimit *
                        incentiveTypeDetail.additionalCredits) /
                      100
                    if (isAnnualSubscription && expirationMonths > 0) {
                      expirationMonths =
                        expirationMonths > 12 ? 12 : expirationMonths
                      additionalCredits = expirationMonths * 12
                    }
                    isMultipleItemsInIncentive = true
                    description = `Increase <span style="color:${planColor}; font-weight: 600"> 
                      ${incentiveTypeDetail.additionalCredits}</span>% Credits`
                    break
                  case AdditionalCreditIncentiveType.SetCredits:
                    additionalCredits = incentiveTypeDetail.additionalCredits
                    isSetCredits = true
                    isMultipleItemsInIncentive = true
                    description = `<span style="color:${planColor}; font-weight: 600">
                      Set to </span>${useNumberWithCommas(additionalCredits, false)} Credit(s)`
                    break
                }
              }
              if (incentiveTypeDetail?.isSetCreditPrice) {
                description = `${description}${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                } <span style="color:${planColor}; font-weight: 600"> $${useNumberWithCommas(
                  incentiveTypeDetail.pricePerCredit,
                  false
                )} </span> per Credit`
                isMultipleItemsInIncentive = true
              }

              if (incentiveTypeDetail?.isSetMaxCredits) {
                description = `${description}${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                } <span style="color:${planColor}; font-weight: 600"> ${useNumberWithCommas(
                  incentiveTypeDetail.numberOfMaxCredits,
                  false
                )} </span> Max Credits`
                isMultipleItemsInIncentive = true
              }

              savingLabel = "Credits"
              pricePerAddOn = (creditAddOn && creditAddOn.pricePerAddOn) || 0
              const creditCount = (creditAddOn && creditAddOn.count) || 0
              additionalAddOnCount = creditCount
              // additionalCredits > creditCount ? creditCount : additionalCredits;

              if (!isSetCredits && incentiveTypeDetail?.isSetCreditPrice) {
                const freeCreditsCount =
                  (creditAddOn && creditAddOn.freeAddOnCount) || 0
                savingAmount = freeCreditsCount * pricePerAddOn
                //savingAmount = additionalAddOnCount * pricePerAddOn;
              }

              if (incentiveTypeDetail.isExpirationSet) {
                switch (incentiveTypeDetail.expireAfter) {
                  case IncentivesExpirationAfter.Months:
                    {
                      expirationMonths = incentiveTypeDetail.expireAfterValue
                      expirationDateTime = new Date(
                        expirationDateTime.setMonth(
                          nowDate.getMonth() +
                            incentiveTypeDetail.expireAfterValue
                        )
                      )
                    }
                    break
                  default:
                    expirationDateTime = null
                    break
                }

                if (expirationDateTime) {
                  expirationDescription = `${
                    expirationDateTime.getMonth() + 1
                  }/${expirationDateTime.getDate()}/${expirationDateTime.getFullYear()}`
                }
              }
            }
          }
          break

        case IncentiveType.SubAccounts:
          {
            incentiveTypeDetail = isUndefinedNullOrEmpty(incentive)
              ? null
              : incentive.subAccounts
            const isSubAccountAddOnAvaialbleInPlan =
              plan.addOnsAllowedInPlan?.find(
                (a) => a.type === SystemAddOnType.SubAccounts
              )?.isAvailableInPlan === true
            if (incentiveTypeDetail) {
              expirationDescription = "No Expiration"

              let additionalSubAccounts = 0
              let isSetSubAccounts = false
              if (incentiveTypeDetail.isSetAdditionalSubAccounts) {
                switch (
                  AdditionalSubAccountIncentives[
                    incentiveTypeDetail.additionalSubAccountType
                  ]
                ) {
                  case AdditionalSubAccountIncentives.IncreaseSubAccounts:
                    additionalSubAccounts =
                      incentiveTypeDetail.additionalSubAccounts
                    break
                  case AdditionalSubAccountIncentives.SetSubAccounts:
                    isSetSubAccounts = true
                    additionalSubAccounts =
                      incentiveTypeDetail.additionalSubAccounts
                    break
                }
              }
              savingLabel = "Sub-Accounts"
              pricePerAddOn =
                (subAccountAddOn && subAccountAddOn.pricePerAddOn) || 0
              // const subAccountCount = (subAccountAddOn && subAccountAddOn.count) || 0;
              // additionalAddOnCount = additionalSubAccounts > subAccountCount ? subAccountCount : additionalSubAccounts;
              // savingAmount = isSubAccountAddOnAvaialbleInPlan ? additionalAddOnCount * pricePerAddOn : 0;

              const freeSubAccountsCount =
                (subAccountAddOn && subAccountAddOn.freeAddOnCount) || 0
              savingAmount = freeSubAccountsCount * pricePerAddOn

              if (isSetSubAccounts) {
                isMultipleItemsInIncentive = true

                description = `Set to <span style="color:${planColor}; font-weight: 600">
                  ${
                    additionalSubAccounts === -1
                      ? "Unlimited"
                      : `${useNumberWithCommas(additionalSubAccounts, false)}`
                  } </span> Sub-Account(s)`
              } else if (
                additionalSubAccounts > 0 ||
                additionalSubAccounts == -1
              ) {
                isMultipleItemsInIncentive = true
                description = `<span style="color:${planColor}; font-weight: 600">
                  ${
                    additionalSubAccounts === -1
                      ? "Unlimited"
                      : useNumberWithCommas(additionalSubAccounts, false)
                  } </span>`
                description = `${description} ${additionalSubAccounts > 1 ? "Extra Sub-Account(s)" : "Sub-Account(s)"}`
              }

              if (incentiveTypeDetail.isSetMaxSubAccounts) {
                description = `${description} ${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                }<span style="color:${planColor}; font-weight: 600">
                  ${useNumberWithCommas(incentiveTypeDetail.numberOfMaxSubAccounts, false)}</span> Max Sub-Account(s)`
                isMultipleItemsInIncentive = true
              }

              if (incentiveTypeDetail?.isSetSubAccountPrice) {
                description = `${description}${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                } <span style="color:${planColor}; font-weight: 600"> $${useNumberWithCommas(
                  incentiveTypeDetail.pricePerSubAccount,
                  false
                )} </span> per Sub-Account`
              }
            }
          }
          break

        case IncentiveType.Users:
          {
            incentiveTypeDetail = isUndefinedNullOrEmpty(incentive)
              ? null
              : incentive.users
            const isUserAddOnAvaialbleInPlan =
              plan.addOnsAllowedInPlan?.find(
                (a) => a.type === SystemAddOnType.Users
              )?.isAvailableInPlan === true
            let isSetUsers = false
            if (incentiveTypeDetail) {
              expirationDescription = "No Expiration"
              let additionalUsers = 0
              if (incentiveTypeDetail.isSetAdditionalUsers) {
                switch (
                  AdditionalUserIncentives[
                    incentiveTypeDetail.additionalUserType
                  ]
                ) {
                  case AdditionalUserIncentives.IncreaseUser:
                    additionalUsers = incentiveTypeDetail.additionalUsers
                    break
                  case AdditionalUserIncentives.SetUsers:
                    isSetUsers = true
                    additionalUsers = incentiveTypeDetail.additionalUsers
                    break
                }
              }
              savingLabel = "Users"
              pricePerAddOn = (userAddOn && userAddOn.pricePerAddOn) || 0
              // const userCount = (userAddOn && userAddOn.count) || 0;
              // additionalAddOnCount = additionalUsers > userCount ? userCount : additionalUsers;
              // savingAmount = isUserAddOnAvaialbleInPlan ? additionalAddOnCount * pricePerAddOn : 0;

              const freeUsersCount =
                (userAddOn && userAddOn.freeAddOnCount) || 0
              savingAmount = freeUsersCount * pricePerAddOn

              if (isSetUsers) {
                isMultipleItemsInIncentive = true
                description = `Set to <span style="color:${planColor}; font-weight: 600">
                    ${useNumberWithCommas(additionalUsers, false)}</span> User(s)`
              } else if (additionalUsers > 0 || additionalUsers == -1) {
                isMultipleItemsInIncentive = true
                description = `<span style="color:${planColor}; font-weight: 600">
                  ${additionalUsers === -1 ? "Unlimited" : useNumberWithCommas(additionalUsers, false)} </span>`
                description = `${description} ${additionalUsers > 0 ? "Extra Users" : "User(s)"}`
              }

              if (incentiveTypeDetail.isSetMaxUsers) {
                description = `${description} ${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                }<span style="color:${planColor}; font-weight: 600">
                  ${useNumberWithCommas(incentiveTypeDetail.numberOfMaxUsers, false)}</span> Max User(s)`
                isMultipleItemsInIncentive = true
              }

              if (incentiveTypeDetail) {
                if (incentiveTypeDetail.isSetUserPrice) {
                  description = `${description}${
                    isMultipleItemsInIncentive ? `<br/>` : ""
                  } <span style="color:${planColor}; font-weight: 600">
                   $${useNumberWithCommas(incentiveTypeDetail.pricePerUser, false)}</span> per User`
                }
              }
            }
          }
          break

        case IncentiveType.IntroductoryPrice:
          {
            incentiveTypeDetail = isUndefinedNullOrEmpty(incentive)
              ? null
              : incentive.introductoryPrice
            if (incentiveTypeDetail) {
              //APPLY INTRODUCTORY INCENTIVE FOR ONLY MONTHLY SUBSCRIPTION
              description = `<span style="color:${planColor}; font-weight: 600">$${incentiveTypeDetail.amount}</span> Introductory Price`
              expirationDescription = "No Expiration"
              expirationMonths = incentiveTypeDetail.expireAfterMonths
              let expirationDateTime = new Date()
              expirationDateTime = new Date(
                expirationDateTime.setMonth(
                  expirationDateTime.getMonth() +
                    incentiveTypeDetail.expireAfterMonths
                )
              )
              savingLabel = "Introductory Price"
              savingAmount = planPrice - incentiveTypeDetail.amount
              if (isAnnualSubscription) {
                planPrice = planPrice / 12
                savingAmount = planPrice - incentiveTypeDetail.amount
                if (expirationMonths <= 12) {
                  savingAmount =
                    ((planPrice - incentiveTypeDetail.amount) *
                      expirationMonths) /
                    12
                }
              }
              expirationDescription = `Expires after ${expirationMonths} ${expirationMonths > 1 ? "months" : "month"}`
            }
          }
          break
        case IncentiveType.CreditMultiplier:
          {
            incentiveTypeDetail = isUndefinedNullOrEmpty(incentive)
              ? null
              : incentive.creditMultiplier

            if (incentiveTypeDetail) {
              expirationDescription = "No Expiration"
              description = `Credit Multiplier`
              if (incentiveTypeDetail.isExpirationSet) {
                switch (incentiveTypeDetail.expireAfter) {
                  case IncentivesExpirationAfter.Months:
                    {
                      expirationMonths = incentiveTypeDetail.expireAfterValue
                      expirationDateTime = new Date(
                        expirationDateTime.setMonth(
                          nowDate.getMonth() + expirationMonths
                        )
                      )
                    }
                    break
                  case IncentivesExpirationAfter.Days:
                    {
                      expirationMonths = incentiveTypeDetail.expireAfterValue
                      expirationDateTime = new Date(
                        expirationDateTime.setDate(
                          nowDate.getDate() +
                            incentiveTypeDetail.expireAfterValue
                        )
                      )
                    }
                    break
                  default:
                    expirationDateTime = null
                    break
                }

                if (!isUndefinedNullOrEmpty(expirationDateTime)) {
                  expirationDescription = `${
                    expirationDateTime.getMonth() + 1
                  }/${expirationDateTime.getDate()}/${expirationDateTime.getFullYear()}`
                }
              }
              if (incentiveTypeDetail.isSetForEmailRoute) {
                description += `<br/><span style="color:${planColor}; font-weight: 600">${incentiveTypeDetail.creditsPerEmail.toFixed(1)}</span> Credits per Email`
              }
              if (incentiveTypeDetail.isSetForSmsRoute) {
                description += `<br/><span style="color:${planColor}; font-weight: 600">${incentiveTypeDetail.creditsPerSms.toFixed(1)}</span> Credits per SMS`
              }
              if (incentiveTypeDetail.isSetForMMSRoute) {
                description += `<br/><span style="color:${planColor}; font-weight: 600">${incentiveTypeDetail.creditsPerMMS.toFixed(1)}</span> Credits per MMS`
              }
            }
          }
          break

        case IncentiveType.Contacts:
          {
            incentiveTypeDetail = isUndefinedNullOrEmpty(incentive)
              ? null
              : incentive.contacts
            if (incentiveTypeDetail) {
              expirationDescription = "No Expiration"
              let additionalContacts = 0
              let isSetContacts = false
              if (
                incentiveTypeDetail.isSetAdditionalContacts &&
                plan.minimumContactLimit !== -1
              ) {
                switch (
                  AdditionalContactIncentives[
                    incentiveTypeDetail.additionalContactType
                  ]
                ) {
                  case AdditionalContactIncentives.IncreaseNumberOfContacts:
                    additionalContacts = incentiveTypeDetail.additionalContacts
                    break
                  case AdditionalContactIncentives.IncreaseContactsByPercentage:
                    additionalContacts = incentiveTypeDetail.additionalContacts
                    additionalContacts =
                      (plan.minimumContactLimit *
                        incentiveTypeDetail.additionalContacts) /
                      100
                    break
                  case AdditionalContactIncentives.SetContacts:
                    isSetContacts = true
                    additionalContacts = incentiveTypeDetail.additionalContacts
                    break
                  case AdditionalContactIncentives.UnlimitedContacts:
                    additionalContacts = -1
                    break
                }
              }
              savingLabel = "Contacts"
              pricePerAddOn = (contactAddOn && contactAddOn.pricePerAddOn) || 0
              const addOnContacts = (contactAddOn && contactAddOn.count) || 0
              additionalAddOnCount =
                additionalContacts > addOnContacts
                  ? addOnContacts
                  : additionalContacts
              savingAmount = additionalAddOnCount * pricePerAddOn

              if (isSetContacts) {
                isMultipleItemsInIncentive = true
                description = `<span style="color:${planColor}; font-weight: 600">
                 Set to </span>${useNumberWithCommas(additionalContacts, false)} Contacts`
              } else if (additionalContacts > 0 || additionalContacts == -1) {
                isMultipleItemsInIncentive = true

                description = `<span style="color:${planColor}; font-weight: 600">
                  ${additionalContacts === -1 ? "Unlimited" : useNumberWithCommas(additionalContacts, false)} </span>`

                description = `${description} ${additionalContacts > 0 ? "Extra Contact(s)" : "Contacts"}`
              }

              if (incentiveTypeDetail.isSetMaxContacts) {
                description = `${description} ${
                  isMultipleItemsInIncentive ? `<br/>` : ""
                } <span style="color:${planColor}; font-weight: 600">
                    ${useNumberWithCommas(incentiveTypeDetail.numberOfMaxContacts, false)}</span> Max Contact(s)`
                isMultipleItemsInIncentive = true
              }

              if (incentiveTypeDetail) {
                if (incentiveTypeDetail.isSetContactPrice) {
                  description = `${description}${
                    isMultipleItemsInIncentive ? "<br/>" : ""
                  } <span style="color:${planColor}; font-weight: 600">
                    $${useNumberWithCommas(incentiveTypeDetail.pricePerContact, false)} </span> per Contact`
                }
              }
            }
          }
          break
      }

      if (isAnnualSubscription) {
        savingAmount = savingAmount * 12
      }
      // if (isAnnualSubscription && savingAmount > 0) {
      //   amountForApplicableDiscount = amountForApplicableDiscount * 12;
      //   if (expirationMonths > 0 && expirationMonths <= 12) {
      //     savingAmount = savingAmount * expirationMonths;
      //   } else {
      //     savingAmount = savingAmount * 12;
      //   }
      // }
      savingAmount = parseFloat(getFormattedAmount(savingAmount))
      if (expirationDescription || description) {
        incentives.push(
          new IncentiveSummary({
            type: incentive.type,
            description: description,
            expirationDescription: expirationDescription,
            expirationMonths: expirationMonths,
            savingLabel: savingLabel,
            savingAmount: savingAmount,
            showInSavings: showInSavings,
          })
        )
      }
    })
    return incentives
  }

  static getSubMinMaxLimitWithIncentives(
    incentives,
    plan,
    isAnnualSubscription
  ) {
    let minValue = plan.minimumSubAccountsLimit
    let maxValue = plan.maximumSubAccountsLimit

    let freeAddOns = 0

    const subAccountIncentive = incentives?.find(
      (i) => IncentiveType[i.type] === IncentiveType.SubAccounts
    )
    if (subAccountIncentive?.subAccounts) {
      if (subAccountIncentive.subAccounts.isSetMaxSubAccounts) {
        const incentiveMaxSubAccountLimit =
          subAccountIncentive.subAccounts.numberOfMaxSubAccounts
        if (plan.maximumSubAccountsLimit === -1) {
          maxValue = -1
        } else if (
          plan.maximumSubAccountsLimit < incentiveMaxSubAccountLimit ||
          incentiveMaxSubAccountLimit === -1
        ) {
          maxValue = incentiveMaxSubAccountLimit
        }
      }
      if (
        subAccountIncentive.subAccounts.isSetAdditionalSubAccounts &&
        plan.minimumSubAccountsLimit !== -1
      ) {
        const additonalSubAccountsValue =
          subAccountIncentive.subAccounts.additionalSubAccounts
        switch (
          AdditionalSubAccountIncentives[
            subAccountIncentive.subAccounts.additionalSubAccountType
          ]
        ) {
          case AdditionalSubAccountIncentives.SetSubAccounts:
            minValue = additonalSubAccountsValue
            break
          case AdditionalSubAccountIncentives.IncreaseSubAccounts:
            // minValue += additonalSubAccountsValue;
            freeAddOns = additonalSubAccountsValue
            break
        }
      }
    }
    if (freeAddOns > 0) {
      minValue += freeAddOns
    }
    if (minValue > maxValue && maxValue != -1) {
      maxValue = minValue
    }
    return {
      minValue: minValue,
      maxValue: maxValue,
      freeAddOns: freeAddOns,
    }
  }

  static getUserMinMaxLimitWithIncentives(
    incentives,
    plan,
    isAnnualSubscription
  ) {
    let minValue = plan.minimumUsersAccountLimit
    let maxValue = plan.maximumUsersAccountLimit

    let freeAddOns = 0
    const userIncentive = incentives?.find(
      (i) => IncentiveType[i.type] === IncentiveType.Users
    )

    if (userIncentive?.users) {
      if (userIncentive.users.isSetMaxUsers) {
        //IF CURRENT LIMIT IS UNLIMITED
        if (plan.maximumUsersAccountLimit !== -1) {
          const incentiveMaxUserLimit = userIncentive.users.numberOfMaxUsers
          //IF INCENTIVE GIVES MORE LIMIT OR UNLIMITED
          if (
            plan.maximumUsersAccountLimit < incentiveMaxUserLimit ||
            incentiveMaxUserLimit == -1
          ) {
            maxValue = incentiveMaxUserLimit
          }
        }
      }
      if (userIncentive.users.isSetAdditionalUsers) {
        switch (
          AdditionalUserIncentives[userIncentive.users.additionalUserType]
        ) {
          case AdditionalUserIncentives.IncreaseUser:
            const additionalUsers = userIncentive.users.additionalUsers
            freeAddOns = additionalUsers
            // minValue += additionalUsers;
            break
        }
      }
    }
    if (freeAddOns > 0) {
      minValue += freeAddOns
    }
    if (minValue > maxValue && maxValue != -1) {
      maxValue = minValue
    }
    return {
      minValue: minValue,
      maxValue: maxValue,
      freeAddOns: freeAddOns,
    }
  }

  static getCreditMinMaxLimitWithIncentives(
    incentives,
    plan,
    isAnnualSubscription
  ) {
    let minValue = plan.minimumCreditLimit
    let maxValue = plan.maximumCreditLimit
    let freeAddOns = 0
    let freeAddOnsForMonths = isAnnualSubscription ? 12 : 1
    const creditIncentive = incentives?.find(
      (i) => IncentiveType[i.type] === IncentiveType.Credits
    )

    if (creditIncentive?.credits) {
      if (
        creditIncentive.credits.isSetMaxCredits &&
        plan.maximumCreditLimit !== -1 &&
        plan.maximumCreditLimit < creditIncentive.credits.numberOfMaxCredits
      ) {
        maxValue = creditIncentive.credits.numberOfMaxCredits
      }
      if (
        isAnnualSubscription &&
        creditIncentive.credits.expireAfterValue > 0
      ) {
        freeAddOnsForMonths =
          creditIncentive.credits.expireAfterValue > 12
            ? 12
            : creditIncentive.credits.expireAfterValue
      }
      if (creditIncentive.credits.isSetAdditionalCredits) {
        const additonalCreditsValue = creditIncentive.credits.additionalCredits
        switch (
          AdditionalCreditIncentiveType[
            creditIncentive.credits.additionalCreditType
          ]
        ) {
          case AdditionalCreditIncentiveType.SetCredits:
            //Set credits just set the value it can be less than the plan value
            minValue = additonalCreditsValue
            break
          case AdditionalCreditIncentiveType.IncreaseCreditsByPercentage:
            let additionalCreditsByPercentage =
              (plan.minimumCreditLimit * additonalCreditsValue) / 100
            // minValue += additionalCreditsByPercentage
            freeAddOns = additionalCreditsByPercentage
            break
          case AdditionalCreditIncentiveType.IncreaseNumberOfCredits:
            // minValue += additonalCreditsValue
            freeAddOns = additonalCreditsValue
            break
        }
      }
    }

    if (minValue != -1 && isAnnualSubscription) {
      minValue = minValue * 12
    }
    // add additional addons with check expiration months
    if (freeAddOns > 0 && freeAddOnsForMonths > 0) {
      minValue += freeAddOns * freeAddOnsForMonths
    }

    if (maxValue != -1 && isAnnualSubscription) {
      maxValue = maxValue * 12
    }

    return {
      minValue: minValue,
      maxValue: maxValue,
      freeAddOns: freeAddOns,
    }
  }

  static getContactMinMaxLimitWithIncentives(incentives, plan) {
    //RULES: IF UPGRADE PLAN OFFERS BETTER THEN USE IT
    //Downgrade: do not use incentive here
    //Add-On: Use current incentive

    let minValue = plan.minimumContactLimit
    let maxValue = plan.maximumContactLimit
    const contactIncentive = incentives?.find(
      (i) => IncentiveType[i.type] === IncentiveType.Contacts
    )

    if (contactIncentive?.contacts) {
      const incentiveMaxContactLimit =
        contactIncentive.contacts?.numberOfMaxContacts

      //MAX CONTACTS
      if (
        contactIncentive.contacts.isSetMaxContacts &&
        plan.maximumContactLimit !== -1 &&
        (plan.maximumContactLimit < incentiveMaxContactLimit ||
          incentiveMaxContactLimit === -1)
      ) {
        maxValue = incentiveMaxContactLimit
      }

      //ADDITIONAL CONTACTS
      if (
        contactIncentive.contacts.isSetAdditionalContacts &&
        plan.minimumContactLimit !== -1
      ) {
        const additonalContactsValue =
          contactIncentive.contacts.additionalContacts
        switch (
          AdditionalContactIncentives[
            contactIncentive.contacts.additionalContactType
          ]
        ) {
          case AdditionalContactIncentives.SetContacts:
            maxValue = additonalContactsValue
            break
          case AdditionalContactIncentives.IncreaseNumberOfContacts:
            minValue += additonalContactsValue
            break
          case AdditionalContactIncentives.IncreaseContactsByPercentage:
            const additionalContactsByPercentage =
              (plan.minimumContactLimit * additonalContactsValue) / 100
            minValue += additionalContactsByPercentage
            break
          case AdditionalContactIncentives.UnlimitedContacts:
            minValue = additonalContactsValue
            break
        }
      }
    }

    return {
      minValue: minValue,
      maxValue: maxValue,
      minPlanValue: plan.minimumContactLimit,
    }
  }

  static filterApplicabledIncentiveByAddOns(selectedPlan, incentives) {
    if (!incentives || !selectedPlan) return []

    //ONLY KEEP ADD ON INCETIVES IF PLAN OFFERS.
    return incentives.filter((i) => {
      switch (IncentiveType[i.type]) {
        case IncentiveType.SubAccounts:
          return (
            selectedPlan?.addOnsAllowedInPlan?.find(
              (a) => a.type === SystemAddOnType.SubAccounts
            )?.isAvailableInPlan === true
          )
          break
        case IncentiveType.Users:
          return (
            selectedPlan?.addOnsAllowedInPlan?.find(
              (a) => a.type === SystemAddOnType.Users
            )?.isAvailableInPlan === true
          )
          break
        case IncentiveType.Training:
          return (
            selectedPlan?.addOnsAllowedInPlan?.find(
              (a) => a.type === SystemAddOnType.PersonalTrainingSession
            )?.isAvailableInPlan === true
          )
          break
        default:
          return true
          break
      }
    })
  }

  static filterIncentivesByRules(selectedPlan, incentives) {
    if (!incentives) {
      return []
    }
    return incentives.filter((i) => this.checkIfKeepIncentive(i, selectedPlan))
  }

  static checkIfKeepIncentive(incentive, plan) {
    let keep = true
    if (!plan) return keep
    switch (IncentiveType[incentive.type]) {
      case IncentiveType.Contacts:
        keep = PlanModel.checkIfKeepContactIncentive(plan, incentive)
        break
      case IncentiveType.Credits:
        keep = PlanModel.checkIfKeepCreditIncentive(plan, incentive)
        break
      case IncentiveType.SubAccounts:
        keep = PlanModel.checkIfKeepSubAccountIncentive(plan, incentive)
        break
      case IncentiveType.Users:
        keep = PlanModel.checkIfKeepUserIncentive(plan, incentive)
        break
      case IncentiveType.CreditMultiplier:
        keep = PlanModel.checkIfKeepCreditMultiplierIncentive(plan, incentive)
        break
    }
    return keep
  }

  static checkIfKeepCreditMultiplierIncentive(plan, creditMultiplierIncentive) {
    const planPerEmailCredits = plan.creditPerEmail
    const planPerSMSCredits = plan.creditPerSMSRoute
    const planPerMMSCredits = plan.creditPerMMSRoute
    let isPlanBetter = false
    if (creditMultiplierIncentive?.creditMultiplier) {
      if (creditMultiplierIncentive.creditMultiplier.isSetForEmailRoute) {
        if (
          planPerEmailCredits <
          creditMultiplierIncentive.creditMultiplier.creditsPerEmail
        ) {
          //plan better here
          isPlanBetter = true
        }
      }

      if (creditMultiplierIncentive.creditMultiplier.isSetForSmsRoute) {
        if (
          planPerSMSCredits <
          creditMultiplierIncentive.creditMultiplier.creditsPerSms
        ) {
          //plan better here
          isPlanBetter = true
        }
      }

      if (creditMultiplierIncentive.creditMultiplier.isSetForMMSRoute) {
        if (
          planPerMMSCredits <
          creditMultiplierIncentive.creditMultiplier.creditsPerMMS
        ) {
          //plan better here
          isPlanBetter = true
        }
      }
    } else {
      isPlanBetter = true
    }

    return isPlanBetter == false
  }

  static checkIfKeepCreditIncentive(plan, creditIncentive) {
    const minValue = plan.minimumCreditLimit

    let isPlanBetter = false
    if (creditIncentive?.credits) {
      if (creditIncentive.credits.isSetMaxCredits) {
        if (
          minValue == -1 ||
          (plan.maximumCreditLimit !== -1 &&
            plan.maximumCreditLimit >
              creditIncentive.credits.numberOfMaxCredits)
        ) {
          //plan better here
          isPlanBetter = true
        }
      }
      if (creditIncentive.credits.isSetAdditionalCredits) {
        const additonalCreditsValue = creditIncentive.credits.additionalCredits
        switch (
          AdditionalCreditIncentiveType[
            creditIncentive.credits.additionalCreditType
          ]
        ) {
          //ONLY THIS CASE NEEDED HERE
          case AdditionalCreditIncentiveType.SetCredits:
            if (minValue == -1 || minValue > additonalCreditsValue)
              isPlanBetter = true
            break
        }
      }
      if (creditIncentive.credits.isSetCreditPrice) {
        if (
          creditIncentive.credits.pricePerCredit > plan.creditPriceAddedToPlan
        ) {
          //plan better here
          isPlanBetter = true
        }

        if (
          creditIncentive.credits.pricePerExtraCredit >
          plan.creditPriceOneTimePurchase
        ) {
          //plan better here
          isPlanBetter = true
        }
      }
    }

    return isPlanBetter == false
  }

  static checkIfKeepContactIncentive(plan, contactIncentive) {
    const minValue = plan.minimumContactLimit
    const maxValue = plan.maximumContactLimit
    let isPlanBetter = false
    if (contactIncentive?.contacts) {
      if (contactIncentive.contacts.isSetMaxContacts) {
        if (
          maxValue == -1 ||
          maxValue > contactIncentive.contacts.numberOfMaxContacts
        ) {
          //plan better here
          isPlanBetter = true
        }
      }
      if (contactIncentive.contacts.isSetAdditionalContacts) {
        const additonalContactsValue =
          contactIncentive.contacts.additionalContacts
        switch (
          AdditionalCreditIncentiveType[
            contactIncentive.contacts.additionalCreditType
          ]
        ) {
          //ONLY THIS CASE NEEDED HERE
          case AdditionalContactIncentives.SetContacts:
            if (minValue == -1 || minValue > additonalContactsValue)
              isPlanBetter = true
            break
        }
      }
    }
    return isPlanBetter == false
  }

  static checkIfKeepUserIncentive(plan, userIncentive) {
    const maxValue = plan.maximumUsersAccountLimit

    const planUserAddOn = plan.addOnsAllowedInPlan?.find(
      (a) => a.type === SystemAddOnType.Users && a.isAvailableInPlan
    )

    let isPlanBetter = false
    if (userIncentive?.users) {
      if (userIncentive.users.isSetMaxUsers) {
        if (maxValue == -1 || maxValue > userIncentive.users.numberOfMaxUsers) {
          //plan better here
          isPlanBetter = true
        }
      }

      if (userIncentive.users.isSetUserPrice) {
        if (planUserAddOn)
          if (userIncentive.users.pricePerUser > planUserAddOn?.price) {
            //plan better here
            isPlanBetter = true
          }
      }
    }
    return isPlanBetter == false
  }

  static checkIfKeepSubAccountIncentive(plan, subAccountIncentive) {
    const maxValue = plan.maximumSubAccountsLimit

    const planSubAccountAddOn = plan.addOnsAllowedInPlan?.find(
      (a) => a.type === SystemAddOnType.SubAccounts && a.isAvailableInPlan
    )

    let isPlanBetter = false
    if (subAccountIncentive?.subAccounts) {
      if (subAccountIncentive.subAccounts.isSetMaxSubAccounts) {
        if (
          maxValue == -1 ||
          maxValue > subAccountIncentive.subAccounts.numberOfMaxSubAccounts
        ) {
          //plan better here
          isPlanBetter = true
        }
      }

      if (subAccountIncentive.subAccounts.isSetSubAccountPrice) {
        if (planSubAccountAddOn)
          if (
            subAccountIncentive.subAccounts.pricePerSubAccount >
            planSubAccountAddOn?.price
          ) {
            //plan better here
            isPlanBetter = true
          }
      }
    }
    return isPlanBetter == false
  }
}
