//DEFAULT VALUES
export const DefaultAppValues = {
  DefaultContactCount: 500,
  DefaultMaximumCount: 100000,
  DefaultCreditsPerEmail: 0.2,
  DefaultCreditsPerSms: 1.0,
  DefaultCreditsPerMMS: 2.0,
}

//Subscription term
export const SubscriptionTerm = {
  Monthly: "Monthly",
  Annually: "Annually",
}

//Standard plan name
export const StandardPlanName = {
  Free: "Free",
  Essentials: "Essentials",
  Premium: "Premium",
  Ultimate: "Ultimate",
}

////////CLIP LOADER COLOR///////////////
export const SpinnerColor = {
  Green: "#67B930",
  Blue: "#007EBF",
}

//API STATUS
export const APIStatus = {
  NONE: "NONE", //for internal use
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  FULFILLED: "FULFILLED",
}

//SYSYTEM FEATURE TYPE
export const SystemFeatureType = {
  CRM: "CRM",
  Campaign: "Campaign",
  MobilePages: "MobilePages",
  LeadFormsAndBanners: "LeadFormsAndBanners",
  Library: "Library",
  Pixbay: "Pixbay",
  Keywords: "Keywords",
  Actions: "Actions",
  MultipleUsers: "MultipleUsers",
  AdvancedCampaignScheduling: "AdvancedCampaignScheduling",
  Automation: "Automation",
  SmartReminders: "SmartReminders",
  DigitalOffer: "DigitalOffer",
  RSVP: "RSVP",
  CustomDomains: "CustomDomains",
  Integrations: "Integrations",
  MultipleSubAccounts: "MultipleSubAccounts",
  ABTesting: "ABTesting",
  ConditionalContent: "ConditionalContent",
  PredectiveSending: "PredectiveSending",
  FraudPrevention: "FraudPrevention",
  BatchScheduling: "BatchScheduling",
  LibrarySyndication: "LibrarySyndication",
  PersonalOnboardingSession: "PersonalOnboardingSession",
}

//CREDIT ROLL OVER FREQUENCY TYPE
export const CreditRollOverFrequencyType = {
  None: "None",
  Day: "Day",
  Month: "Month",
  Year: "Year",
}

//INCENTIVE TYPE
export const IncentiveType = {
  Contacts: "Contacts",
  Credits: "Credits",
  SubAccounts: "SubAccounts",
  Users: "Users",
  DiscountPrice: "DiscountPrice",
  IntroductoryPrice: "IntroductoryPrice",
  FreeAccount: "FreeAccount",
  UpgradeFeatures: "UpgradeFeatures",
  CreditMultiplier: "CreditMultiplier",
  CreditRollOver: "CreditRollOver",
  Visits: "Visits",
  LibraryStorage: "LibraryStorage",
  TrialExtension: "TrialExtension",
  Number: "Number",
  Training: "Training",
}

//DISCOUNT PRICE APPLICABLE ON
export const DiscountPriceApplicableOn = {
  Plan: "Plan",
  AddOns: "AddOns",
  EntirePurchase: "EntirePurchase",
}

//DISCOUNT PRICE BASED ON
export const DiscountPriceBasedOn = {
  Amount: "Amount",
  Percentage: "Percentage",
  SetAmount: "SetAmount",
}

//DISCOUNT PRICE EXPIRATION AFTER
export const DiscountPriceExpirationAfter = {
  Months: "Months",
  Payments: "Payments",
}

//ADDISTIONAL SUBACCOUNT INCENTIVES
export const AdditionalSubAccountIncentives = {
  IncreaseSubAccounts: "IncreaseSubAccounts",
  SetSubAccounts: "SetSubAccounts",
}

//ADDITIONAL USER INCENTIVES
export const AdditionalUserIncentives = {
  IncreaseUser: "IncreaseUser",
  SetUsers: "SetUsers",
}

//ADDITIONAL CONTACT INCENTIVES
export const AdditionalContactIncentives = {
  IncreaseNumberOfContacts: "IncreaseNumberOfContacts",
  IncreaseContactsByPercentage: "IncreaseContactsByPercentage",
  SetContacts: "SetContacts",
  UnlimitedContacts: "UnlimitedContacts",
}

//INCENTIVES EXPIRATION AFTER
export const IncentivesExpirationAfter = {
  Days: "Days",
  Months: "Months",
}

//ADDITIONAL CREDITS INCENTIVES
export const AdditionalCreditIncentiveType = {
  IncreaseNumberOfCredits: "IncreaseNumberOfCredits",
  IncreaseCreditsByPercentage: "IncreaseCreditsByPercentage",
  SetCredits: "SetCredits",
}

//SYSTEM ADD ON TYPE
export const SystemAddOnType = {
  Users: "Users",
  SubAccounts: "SubAccounts",
  TollFreeOrLocalNumbers: "TollFreeOrLocalNumbers",
  PersonalTrainingSession: "PersonalTrainingSession",
  DedicatedEmailIPAddress: "DedicatedEmailIPAddress",
  ConversationsLicense: "ConversationsLicense",
}

export const PricingTab = {
  Pricing: "Pricing",
  SignUp: "SignUp",
}

export const AddOnFeatureType = {
  Credits: "Credits",
  Contacts: "Contacts",
  Subscription: "Subscription",
  SubAccount: "SubAccount",
  User: "User",
  Number: "Number",
  TrainingSession: "TrainingSession",
  DedicatedEmailIP: "DedicatedEmailIP",
}
