import { useEffect } from "react"

const useScript = (url, addInHeader = false) => {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = url
    script.async = true

    if (addInHeader) {
      document.head.appendChild(script)
    } else {
      document.body.appendChild(script)
    }

    return () => {
      if (addInHeader) {
        document.head.removeChild(script)
      } else {
        document.body.removeChild(script)
      }
    }
  }, [url])
}

export default useScript
