//check value is undefined, null or empty
export function isUndefinedNullOrEmpty(value) {
  if (value === undefined || value === "" || value === null) {
    return true
  }
  return false
}

//get number with commas
export function numberWithCommas(x) {
  return !isUndefinedNullOrEmpty(x)
    ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : x
}

export function getCookieItem(c_name) {
  if (typeof document !== "undefined") {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x === c_name) {
        return unescape(y);
      }
    }
  }
  return "";
};

export function setCookieItem(c_name, value, numberOfDays) {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  if (typeof document !== undefined) {
    document.cookie = `${c_name}=${value}; expires=${now.toUTCString()}; path=/`;
  }
};

export function redirectUrl(url, openNewWindow = true) {
  if (openNewWindow) {
    const windowResponse = window.open(url, '_blank');
    if (windowResponse) {
      return;
    }
  }
  window.open(url, '_self');
};

export const getKeyFromObjectValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

//use numder with commas
export function useNumberWithCommas(x, needToFixed = true) {
  if (isUndefinedNullOrEmpty(x)) {
    x = 0;
  }
  x = needToFixed ? getFormattedAmount(x) : x;
  if (x.toString().includes('.')) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d).)/g, ',');
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getFormattedAmount = amount => {
  return (Math.round(amount * 100) / 100).toFixed(2);
};
