import { css } from "styled-components"

const sizes = {
  se: 320,
  phone: 375,
  phablet: 650,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200,
}

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export default media
